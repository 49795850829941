<template>
  <div class="card card-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-5 col-md-4">
          <slot name="header">
            <div class="icon-big text-center"
              :class="`text-${type}`">
              <el-tooltip :content="`${statusText}`" :open-delay="100" placement="top">
                <p-button :type="`${type}`" size="md" v-on:click="openrdp(`${online}`, `${clickable}`, `${smallTitle}`)">
                  <i :class="icon" class="statusIcon"></i>
                </p-button>
              </el-tooltip>
            </div>
          </slot>
        </div>
        <div class="col-7 col-md-8">
          <slot name="content">
            <div class="numbers">
              <p class="card-category">{{title}}</p>
              <p class="card-title">{{smallTitle}}</p>
              <slot name="button"></slot>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <hr/>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
  import {Tooltip} from 'element-ui'
  import Constants from 'src/assets/constants'

  const user_data = JSON.parse(localStorage.getItem('user-info'));
  export default {
    components: {
      [Tooltip.name]: Tooltip
    },
    data() {
      return {
        walletapp: Constants.methods.walletme(), adminusr: false,
      }
    },
    name: 'stats-card',
    props:{
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        description: 'Card title'
      },
      smallTitle: {
        type: String,
        description: 'Card small title'
      },
      icon: {
        type: String,
        description: 'Card icon'
      },
      statusText: {
        type: String,
        description: 'Card icon Text'
      },
      online: {
        type: Boolean,
        description: 'Status of RDP'
      },
      clickable: {
        type: Boolean,
        description: 'clickable or not'
      }
    },
    methods: {
      openrdp(online, clickable, rdp) {
        console.log(online, clickable);
        // Only when its online, proceed to open window
        if (online == 'true' && clickable == 'true') {
          window.open("/webrdp/"+ rdp);
        }
        else {
          if (!this.adminusr && this.walletapp && online == 'true') { // Show Modal that wallet balance is zero
            this.$emit('zero-balance');
          }
        }
      }
    },
    mounted() {
      if (user_data['roles'].indexOf('admin') >= 0) {
        this.adminusr = true;
      }
    }
  }

</script>
<style scoped>
  .statusIcon {
    line-height: normal !important;
  }
  .card-category {
    font-size: 0.35em !important;
  }
  .card-title {
    font-size: 0.65em;
  }
</style>
