<template>
  <module
    ref="module"
    id="partnerbrokerstxt"
    titleIcon="fa fa-handshake-o"
    :title="$t('partnerbrokerstxt')"
    :use-default-list="false"
  >
    <template slot="toolbar-global">
      <div class="mr-3">
        <fg-input class="input-sm"
                  :placeholder="searchbrokertxt"
                  v-model="searchb"
                  addon-right-icon="nc-icon nc-zoom-split"
                  v-on:keyup="searchcb">
        </fg-input>
      </div>
    </template>
    <div slot="global">
      <!-- Modal to notify user where to change setting -->
      <modal :show.sync="modalnotice" headerClasses="justify-content-center" modalClasses="modal-lg">
        <h5 slot="header" class="title title-up">{{ modal_title }}</h5>

        <div class="row" style="padding-bottom: 0;">
          <div class="table-responsive">
            <table class="table">
              <tbody>
              <tr v-if="adminusr && newbroker">
                <td style="border-top: 0;">{{ $t('nametxt') }}</td>
                <td class="text-right" style="border-top: 0;">
                  {{ brokerinfo.name }}
                </td>
              </tr>
              <tr v-if="adminusr && newbroker">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input v-model="brokerinfo.name" type="text" @change="modifyBroker"></fg-input>
                </td>
              </tr>
              <tr v-if="adminusr && brokerinfo.logo && brokerinfo.logo !== 'null' && brokerinfo.name">
                <td colspan="2" class="text-center" style="border-top: 0;" v-if="brokerinfo.logo">
                  <img :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${brokerinfo.logo}.png`">
                </td>
              </tr>
              <tr v-if="adminusr && brokerinfo.name">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <vue-dropzone id="bannerupload" :options="dropOptions"
                                ref="dropzone" @vdropzone-file-added="logoAdded"
                                @vdropzone-complete="logoUploaded" @vdropzone-sending="injectLink"></vue-dropzone>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td>ADMIN {{ $t('email') }} #1</td>
                <td class="text-right">
                  {{ brokerinfo.admineml }}
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input
                    type="text"
                    name="admineml"
                    v-model="brokerinfo.admineml"
                    v-validate="modelValidations.email"
                    :error="getError('admineml')"
                    @change="validateUserExists"
                  ></fg-input>
                </td>
              </tr>
              <tr>
                  <td colspan="2">
                    <span> {{ $t('platformtxt') }} </span>
                    <el-select
                      class="w-100 mb-2"
                      v-if="adminusr"
                      v-model="brokerinfo.platform">
                      <el-option value="both" :label="$t('bothtxt')"></el-option>
                      <el-option value="mt4" label="MT4"></el-option>
                      <el-option value="mt5" label="MT5"></el-option>

                    </el-select>
                  </td>
              </tr>
              <tr>
                <td colspan="2" :class="(adminusr) ? '' : 'text-center'">
                  <h6 v-if="adminusr">{{ $t('plantxt') }}:</h6>
                  <el-select
                    class="w-100 mb-2"
                    v-if="adminusr"
                    v-model="brokerinfo.plan">
                    <el-option value="" label=""></el-option>
                    <el-option v-for="(item, index) in brokerPLan" :value="item" :key="index">
                        {{item}}
                    </el-option>
                  </el-select>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td>(ADMIN) Copier Fee</td>
                <td class="text-right">
                  {{ brokerinfo.copier_fee }}
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input v-model="brokerinfo.copier_fee" type="number" min=0></fg-input>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td>(ADMIN) Broker Monthly Fee</td>
                <td class="text-right">
                  {{ brokerinfo.monthly_fee }}
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input v-model="brokerinfo.monthly_fee" type="number" min=0></fg-input>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2">
                  <div class="row">
                    <div class="col-md-8">
                      (ADMIN) $ per lot traded
                    </div>
                    <div class="col-md-4 text-right">
                      {{ brokerinfo.billing_per_lot_traded || 0 }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input v-model="brokerinfo.billing_per_lot_traded" type="number" min=0 step="0.1"></fg-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('compensationtxt') }}</td>
                <td class="text-right">
                  {{ brokerinfo.compensation }}
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input v-model="brokerinfo.compensation" type="text"></fg-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('freevps') }}</td>
                <td class="text-right" v-if="adminusr">
                  <p-checkbox v-model="brokerinfo.vps"></p-checkbox>
                </td>
                <td class="text-right" :class="!brokerinfo.vps ? 'text-danger' : 'text-success'" v-else>
                  <i class="nc-icon" :class="!brokerinfo.vps ? 'nc-simple-remove' : 'nc-check-2'"
                     style="font-weight: bolder"></i>
                </td>
              </tr>
              <tr>
                <td>{{ $t('freehokocopier') }}</td>
                <td class="text-right" v-if="adminusr">
                  <p-checkbox v-model="brokerinfo.copier"></p-checkbox>
                </td>
                <td class="text-right" :class="!brokerinfo.copier ? 'text-danger' : 'text-success'" v-else>
                  <i class="nc-icon" :class="!brokerinfo.copier ? 'nc-simple-remove' : 'nc-check-2'"
                     style="font-weight: bolder"></i>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td>{{ $t('displayonwallettransfer') }}</td>
                <td class="text-right">
                  <p-checkbox v-model="brokerinfo.wallet_transfer"></p-checkbox>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td> {{ $t('broker_internal_debit_txt') }} </td>
                <td class="text-right">
                  <p-checkbox v-model="brokerinfo.internal_debit"></p-checkbox>
                </td>
              </tr>
              <tr>
                <td> {{ $t('broker_internal_credit_txt') }} </td>
                <td class="text-right">
                  <p-checkbox v-model="brokerinfo.internal_credit"></p-checkbox>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td> {{ $t('broker_active_integration') }} </td>
                <td class="text-right">
                  <p-checkbox v-model="brokerinfo.active_integration"></p-checkbox>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td> {{ $t('broker_restrict_follow') }} </td>
                <td class="text-right">
                  <p-checkbox v-model="brokerinfo.restrict_follow"></p-checkbox>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td> Transfer {{ $t('email') }}</td>
                <td class="text-right">
                  {{ brokerinfo.transfereml }}
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input v-model="brokerinfo.transfereml" type="text"></fg-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('cashbacktxt') }}</td>
                <td class="text-right">
                  {{ $t('uptotxt') }} ${{ brokerinfo.cashback }} {{ $t('per10lots') }}
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input v-model="brokerinfo.cashback" type="number"></fg-input>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td>{{ $t('sortbyorder') }}</td>
                <td class="text-right">
                  <fg-input v-model="brokerinfo.sort_order" type="number"></fg-input>
                </td>
              </tr>
              <tr>
                <td colspan="2" :class="(adminusr) ? '' : 'text-center'">
                  <h6 v-if="adminusr">{{ $t('linktxt') }}:</h6>
                  <fg-input v-if="adminusr" v-model="brokerinfo.link" type="text"></fg-input>

                  <p-button type="info" @click.native="openwin(`${brokerinfo.link}`)" v-if="!adminusr">
                    {{ $t('openaccountbroker') }}
                  </p-button>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td>{{ $t('email') }} #1</td>
                <td class="text-right">
                  {{ brokerinfo.primaryeml }}
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input
                    type="text"
                    name="primaryeml"
                    v-model="brokerinfo.primaryeml"
                    v-validate="modelValidations.email"
                    :error="getError('primaryeml')"
                  ></fg-input>
                </td>
              </tr>
              <tr v-if="adminusr">
                <td>{{ $t('email') }} #2</td>
                <td class="text-right">
                  {{ brokerinfo.secondary }}
                </td>
              </tr>
              <tr v-if="adminusr">
                <td colspan="2" style="border-top: 0; padding-top: 0;">
                  <fg-input
                    type="text"
                    name="secondary"
                    v-model="brokerinfo.secondary"
                    v-validate="modelValidations.email"
                    :error="getError('secondary')"
                  ></fg-input>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <template slot="footer" v-if="adminusr">
          <div class="py-3 mx-auto" v-if="modalinprogress">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
          </div>
          <div class="left-side" v-if="modalinprogress == false">
            <p-button type="success" @click.prevent="validate" link v-if="newbroker">{{ $t('addbroker') }}</p-button>
            <p-button type="success" @click.prevent="validate" link v-if="!newbroker">{{ $t('upentrybtn') }}</p-button>
          </div>
          <div class="divider" v-if="modalinprogress == false"></div>
          <div class="right-side" v-if="modalinprogress == false">
            <p-button type="danger" @click.prevent="modalnotice = false" link>{{ $t('cancel') }}</p-button>
          </div>
        </template>

        <template slot="footer" v-else>
          <p-button type="info" @click.prevent="modalnotice = false" link>{{ $t('gotit') }}</p-button>
        </template>
      </modal>
      <!-- End Modal -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else>
        <div class="row" style="margin-top: 10px">
          <div class="col-lg-3 col-md-6 col-sm-6" v-for="stats in statsCards">

            <div class="card default">

              <div class="card-header">

                <div class="broker-logo">
                  <img :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${stats.logo}.png`"
                       v-if="!!stats.logo">
                  <img src="" alt="Broker" v-if="!stats.logo">
                </div>

                <div class="float-right">
                  <p-button type="info" icon round outline @click.native="moreinfo(`${stats.id}`)" v-if="adminusr">
                    <i class="fa fa-cog"></i>
                  </p-button>
                </div>

                <div class="row" style="padding-bottom: 30%">
                </div>

                <div v-if="stats.name.length<=7">
                  <h4 class="card-title">
                    {{ stats.name }}
                  </h4>
                </div>
                <div v-if="stats.name.length>7 && stats.name.length<16">
                  <h5 class="card-title" style="align-content: center">
                    {{ stats.name }}
                  </h5>
                </div>
                <div v-if="stats.name.length>=16 && stats.name.length<22">
                  <h6 class="card-title" style="align-content: center; padding-top: 4px">
                    {{ stats.name }}
                  </h6>
                </div>
                <div v-if="stats.name.length>=22">
                  <h6 class="card-title">
                    {{ stats.name }}
                  </h6>
                </div>


              </div>
              <div class="card-body">
                <div class="row">

                  <div class="col-md-12">

                    <div class="table-responsive" v-if="!hideBrokerInfo">
                      <table class="table">
                        <tbody>
                        <tr>
                          <td>{{ $t('freevps') }}</td>
                          <td class="text-right" :class="!stats.vps ? 'text-danger' : 'text-success'">
                            <i class="nc-icon" :class="!stats.vps ? 'nc-simple-remove' : 'nc-check-2'"
                               style="font-weight: bolder"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('freehokocopier') }}</td>
                          <td class="text-right" :class="!stats.copier ? 'text-danger' : 'text-success'">
                            <i class="nc-icon" :class="!stats.copier ? 'nc-simple-remove' : 'nc-check-2'"
                               style="font-weight: bolder"></i>
                          </td>
                        </tr>
                        <tr>
                          <td> {{ $t('premium_accounts_txt') }}</td>
                          <td class="text-right" :class="!stats.cashback ? 'text-danger' : 'text-success'">
                            <i class="nc-icon" :class="!stats.cashback ? 'nc-simple-remove' : 'nc-check-2'"
                               style="font-weight: bolder"></i>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
              <hr style="margin: 0;"/>
              <div class="card-footer text-center modal-footer" v-if="stats">
                <p-button type="info" @click.native="openwin(stats)" link>
                  <i class="nc-icon nc-simple-add"></i>
                  {{ $t('openaccountbroker') }}
                </p-button>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>

  </module>
</template>
<script>
import {Button, Card, Modal} from 'src/components/UIComponents';
import {Tooltip} from 'element-ui';
import PPagination from 'src/components/UIComponents/Pagination.vue';
import StatsCard from 'src/components/UIComponents/Cards/StatsCardAdv';
import vueDropzone from "vue2-dropzone";
import VueCookies from 'vue-cookies';
import Constants from 'src/assets/constants';

const user_data = JSON.parse(localStorage.getItem('user-info'));
const timercountdown = 1500;

const upload_url = "https://dash.hokocloud.com/api/v3/upbroker/";
const rules = Constants.methods.getRules();
const auth_headers = {
  'Authorization': 'Bearer ' + VueCookies.get('dash-at'),
  'idtoken': VueCookies.get('dash-it')
};

export default {
  components: {
    Card,
    [Button.name]: Button,
    PPagination,
    [Tooltip.name]:
    Tooltip,
    StatsCard,
    Modal,
    vueDropzone
  },
  data() {
    return {
      inprogress: true,
      inprogresstxt: this.$t('loadingtxt') + " " + this.$t('partnerbrokerstxt') + ". " + this.$t('pleasewait'),
      statsCards: [],
      modalnotice: false,
      modal_title: null,
      brokerinfo: {},
      adminusr: false,
      modalinprogress: false,
      newbroker: false,
      dropOptions: {
        url: upload_url,
        maxFilesize: 1,
        maxFiles: 1,
        thumbnailHeight: 90,
        thumbnailMethod: 'contain',
        resizeHeight: 90,
        acceptedFiles: "image/png,image/jpg,image/jpeg,image/gif",
        autoProcessQueue: false,
        headers: auth_headers,
        dictDefaultMessage: "<i class='nc-icon nc-cloud-upload-94' style='font-size: 35px;'></i> " + this.$t('uploadtxt')
      },
      searchb: null,
      modalwarn: null,
      fileadded: false,
      brokerservers: {},
      clonedBrokers: [],
      searchbrokertxt: this.$t('searchbroker'),
      hideBrokerInfo: rules.hide_info_broker,
      modelValidations: {
        email: {
          email: true
        },
      },
      brokerPLan: [
        'HokoCloud Copy-Trading + Social Trading Open Ended Solution (Plan A)',
        'HokoCloud Base White Label Solution for Copy-Trading + Social Trading + VPS Provision (Plan B)',
        'HokoCloud Base White Label Solution for VPS Provision'
      ]
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName) ? this.$t('useridnotexist') : ''
    },
    searchcb() {
      this.statsCards = [];

      let allbrokers = this.clonedBrokers.slice(0);
      let searchbr = null;
      if (this.searchb !== null && this.searchb.length > 0) {
        searchbr = new RegExp(this.searchb, 'gi');
      }

      for (var i = 0; i < allbrokers.length; i++) {
        let addto = true;
        let brokername = allbrokers[i]['name'] + " " + allbrokers[i]['primaryeml'] + " " + allbrokers[i]["secondary"];
        brokername += allbrokers[i]['admineml'];

        if (searchbr !== null && brokername.match(searchbr) === null) {
          addto = false
        }
        // Add to the
        if (addto) {
          this.statsCards.push(allbrokers[i]);
        }
      }
    },
    modifyBroker() {
      const fname = this.brokerinfo.name.replace(/\s/g, '');
      this.brokerinfo.logo = fname;
      //this.dropOptions.url = upload_url + fname;
      this.$refs.dropzone.setOption('url', upload_url + fname);
    },
    logoAdded(file) {
      this.alert_me = false;
      if (file.status == "error") {
        this.modalwarn = "Error of file being uploaded";
      } else {
        const fname = this.brokerinfo.name.replace(/\s/g, '');
        this.brokerinfo.logo = fname;
        this.$refs.dropzone.setOption('url', upload_url + fname);
        this.fileadded = true;
      }
    },
    logoUploaded(file) {
      this.modalwarn = 'Logo for ' + this.brokerinfo.name + ' is uploaded';
      this.upsertBroker();
      //setTimeout(this.cleanUp, timercountdown);
    },
    injectLink(file, xhr, formData) {
      const fname = this.brokerinfo.name.replace(/\s/g, '');
      formData.append('logo_url', fname);
    },
    newinfo() {
      this.newbroker = true;
      this.modal_title = this.$t('newbroker');
      this.brokerinfo = {'sort_order': this.statsCards.length + 1};
      this.modalnotice = true;
    },
    moreinfo(bid) {
      let bidx = this.statsCards.findIndex(b => b.id === parseInt(bid));
      if (bidx >= 0) {
        this.modal_title = this.statsCards[bidx].name;
        this.brokerinfo = Object.assign({}, this.statsCards[bidx]);
      }
      this.modalnotice = true;
    },
    loadAll(resp) {
      resp.data.sort(function (a, b) {
        if (a.sort_order == b.sort_order) {
          return 0;
        }
        return a.sort_order > b.sort_order ? 1 : -1;
      });

      this.statsCards = resp.data;
      this.clonedBrokers = this.statsCards.slice(0);
      this.inprogress = false;
    },
    closeModal() {
      this.modalnotice = false;
      this.fileadded = false;
      this.brokerinfo = {};

      this.$getPartnerBrokers_v2({
        brokerId: null,
        user: !this.adminusr,
        copier: false
      }).then(this.loadAll, this.failop);
    },
    updatedBroker(resp) {
      this.$toast.success(this.$t(resp.msg))
      this.modalinprogress = false;
      setTimeout(this.closeModal, timercountdown);
    },
    showOpenAccount(stats) {
      return !!url && url != 'N/A';
    },
    openwin(stats) {
      let url = stats.wlUrl ? stats.wlUrl : stats.link;
      if (url == 'N/A') {
        this.$toast.warning(this.$t('urlnotfound'));
        return;
      }
      window.open(url, '_blank');

      let json_data = {
        'broker_name': stats.logo,
        'broker_link': stats.link,
        'email': user_data.email,
        'first_name': user_data.user_metadata.first_name,
        'last_name': user_data.user_metadata.last_name
      }
      this.$sendPartnerBrokerEmail(json_data)
    },
    delBroker(bid) {
      this.$delPartnerBrokers(bid).then(this.closeModal, this.failop);
    },
    upsertBroker() {
      if (!this.brokerinfo.admineml && !this.brokerinfo.admineml) {
        this.brokerinfo.admineml = "";
      }
      this.$upPartnerBrokers(this.brokerinfo.id, this.brokerinfo)
        .then(this.updatedBroker, this.failop);
    },
    normalizeEmails() {
      this.brokerinfo.admineml = this.brokerinfo.admineml ? this.brokerinfo.admineml.trim() : this.brokerinfo.admineml;
      this.brokerinfo.primaryeml = this.brokerinfo.primaryeml ? this.brokerinfo.primaryeml.trim() : this.brokerinfo.primaryeml;
      this.brokerinfo.secondary = this.brokerinfo.secondary ? this.brokerinfo.secondary.trim() : this.brokerinfo.secondary;
    },
    async validateUserExists() {
      this.normalizeEmails();
      if (!this.brokerinfo.admineml) {
        return true;
      }
      const exists = await this.$checkIfUserExists(this.brokerinfo.admineml);
      if (!exists.success) {
        this.$toast.warning(this.$t('admineml_notfound'));
        return false;
      }
      return true;
    },
    async validate() {
      this.modalinprogress = true;
      const userExists = await this.validateUserExists();
      const isValid = await this.$validator.validateAll();
      if (!isValid || !userExists) {
        this.modalinprogress = false;
        return;
      }
      if (this.newbroker || this.fileadded) {
        this.$refs.dropzone.processQueue()
      } else {
        this.upsertBroker();
      }
    },
    failop(err) {
      const msg = err.msg ? this.$t(err.msg) : this.$t('unkownerror');
      this.$toast.warning(msg);
      this.modalinprogress = false;
    }
  },
  mounted() {
    if (user_data['roles'].indexOf('admin') >= 0) {
      this.adminusr = true;
    }
    this.$getPartnerBrokers_v2({
      brokerId: null,
      user: !this.adminusr,
      copier: false
    }).then(this.loadAll, this.failop);
  },
  created() {
    this.$setTranslatingInProgress()
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress")
  },
}
</script>
<style lang="scss" scoped>
.card-header {
  flex: 170px;
  padding: 5px;
  align-content: center;
}

@media (min-width: 600px) {
  h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  h5 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  h6 {
    font-size: 0.75rem;
    font-weight: bold;
  }
}

@media (min-width: 1200px) {
  h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    font-weight: bold;
  }
  h6 {
    font-size: 0.674rem;
    font-weight: bold;
  }
}

@media (min-width: 1800px) {
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  h6 {
    font-size: 1rem;
    font-weight: bold;
  }
}

.card-title {
  align-content: center;
  padding: 0 10px 0 10px;
}

.broker-logo {
  padding: 5px 10px 10px 39%;
}

.broker-logo img {
  border-radius: 50%;
  width: 55px;
  border: 1px solid silver;
}

.table {
  margin-bottom: 0;
}

.table-responsive {
  overflow: hidden;
}
</style>
